<template>
    <v-layout class="justify-center">
      <div class="emptyCard">
        <div class="text-2xl">
          Willkommen bei Familytablet.
        </div>
        <div class="text-base m-5">
          Aktuell gibt es noch keine Nachrichten.
        </div>
      </div>
    </v-layout>
</template>

<script>

export default {
  name: 'CardItem',
  props: {},
  components: {}
}
</script>
<style>
.v-application ul, .v-application ol {
  padding-left: 0px !important;
}
.emptyCard {
  height: 600px;
  margin-top: 200px;
  padding: 20px;
}
</style>

<template>
  <video
    :src="videoUrl"
    :autoplay="videoAutoplay"
    :controls="videoControls"
    :loop="videoLoop"
    class="mx-auto"
    :style='style'
    :ref='GetId'
    v-observe-visibility="PlayWhenVisible"
  />
</template>

<script>

export default {
  name: 'ImageComponent',
  props: {
    videoUrl: String,
    videoAutoplay: Boolean,
    videoLoop: Boolean,
    videoControls: Boolean,
    height: String,
    reference: Number
  },
  computed: {
    style () {
      return 'max-height: 480px'
    },
    GetId () {
      return this.BuildId()
    }
  },
  methods: {
    PlayWhenVisible () {
      // eslint-disable-next-line no-debugger
      // debugger
      const video = this.$refs[this.BuildId()]
      if (video.paused) {
        setTimeout(function () {
          video.play()
        }, 150)
      } else {
        video.pause()
      }
    },
    BuildId () {
      return 'video_' + this.reference
    }
  }

}

</script>
<style>

.v-application p {
  margin-bottom: 0px!important;
}
</style>

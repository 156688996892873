
<template>
  <tablet-stream></tablet-stream>
</template>
<script>
import TabletStream from '@/components/streams/TabletStream.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TabletStream
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    if (!this.user.loggedIn) {
      this.$router.push('/login')
    }
  }
}
</script>


<template>
    <v-card :width="cardWidth" class="correctPosition">
      <div v-if="assetType === 'video'">
        <video-component
          :videoUrl="assetUrl"
          :videoAutoplay="videoAutoplay"
          :videoControls="videoControls"
          :videoLoop="videoLoop"
          :height="assetHeight"
          :reference="reference"
        />
      </div>
      <div v-else-if="assetType === 'image'">
        <image-component :imageUrl="assetUrl" :height="assetHeight"/>
      </div>
      <div v-else>
      </div>
      <v-card-text class="bg-specialgreen text-white avatarBoxHeight">
          <v-row align-center>
            <v-col cols="3" align="center">
              <v-avatar size="80" class="mt-3">
                <img
                  :alt="'user'"
                  :src="avatarImgUrl"
                >
              </v-avatar>
            </v-col>
            <v-col cols="9" align="left" class="p-9">
              <p class="text-2xl">
                {{ msg }}
              </p>
              <p class="mt-1 text-gray-300 text-lg">
                Gesendet von {{ avatarName }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import ImageComponent from '../assets/ImageComponent.vue'
import VideoComponent from '../assets/VideoComponent.vue'

export default {
  name: 'CardItem',
  props: {
    assetUrl: String,
    assetType: String,
    avatarImgUrl: String,
    avatarName: String,
    msg: String,
    cardWidth: Number,
    videoControls: Boolean,
    videoAutoplay: Boolean,
    videoLoop: Boolean,
    assetHeight: String,
    reference: Number
  },
  components: {
    ImageComponent, VideoComponent
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';

.bg-specialgreen {
  background-color: $card-color;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.correctPosition {
  margin-top: -20px;
}
</style>
